export default {
    state: {
        ws: '',
        wsTimer: ''
    },

    getters: {},
    mutations: {
        wsInit(state, ws) {
            state.ws = ws;
            const isHttps = 'https:' === document.location.protocol;
            if (isHttps) {
                state.ws = new WebSocket('wss://swfk.dxtk-tech.cn/wss/');
            } else {
                state.ws = new WebSocket('ws://59.110.67.66:8296'); //线上
                // state.ws = new WebSocket('ws://192.168.0.189:8296'); //本地
            }
        },

        wsHeartbeatStart(state, ip) {
            state.ws.send('1001' + ip);
            state.wsTimer = setInterval(() => {
                state.ws.send('1000' + ip);
            }, 3000);
        },

        wsHeartbeatStop(state) {
            clearInterval(state.wsTimer);
        },

        closeWs(state) {
            state.ws.close();
            state.ws = '';
        }
    },
    actions: {}
}
